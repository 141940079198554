import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import * as styled from './styles';

import { Heading } from '@components/type';
import Image from '@components/Image';

function TimelineTab({ heading, image, description, active, click }) {
  return (
    <styled.Wrapper active={active} onClick={click}>
      {get(image, 'image.asset.fluid') && (
        <styled.AssetWrapper>
          <Image
            fluid={image.image.asset.fluid}
            edge={0}
            ratio={9 / 16}
            altText={image.altText}
          />
        </styled.AssetWrapper>
      )}
      <styled.Info>
        <Heading levels={[500, 300, 200]}>{heading}</Heading>
        <styled.Body>{description}</styled.Body>
      </styled.Info>
    </styled.Wrapper>
  );
}

TimelineTab.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  active: PropTypes.bool,
};

export default TimelineTab;
