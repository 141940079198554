import React, { useState } from 'react';
import { useIntl } from '@utils/localize';

import * as styled from './styles';

import Container from '@components/Container';
import TimelineTab from '../TimelineTab';
import TimelineItem from '../TimelineItem';
import { Heading } from '@components/type';

function Timeline({ items, colorTheme }) {
  const intl = useIntl();

  const [active, setActive] = useState(0);

  function handleNext(timeline) {
    setActive((active + 1) % 3);

    document.querySelector('#tab-container').scrollIntoView({
      behavior: 'smooth',
    });
  }

  const activeItem = items[active];
  const nextItem = items[(active + 1) % 3];

  return (
    <Container centered>
      <styled.Wrapper>
        <styled.TabContainer id="tab-container">
          {items.map((item, idx) => (
            <TimelineTab
              key={idx}
              heading={item.heading}
              image={item.imageExtended}
              description={item.description}
              active={active === idx}
              click={() => setActive(idx)}
              colorTheme={colorTheme}
            />
          ))}
        </styled.TabContainer>
        <styled.Line />
        <div>
          {activeItem.items.map((item, idx) => (
            <TimelineItem
              key={idx}
              heading={item.heading}
              image={item.image}
              description={item.description}
              hour={item.hour}
              meridian={item.meridian}
              inverted={item.textAlignment === 'left'}
              quote={item.quote}
              relatedStory={item.relatedStory}
              tilted={item.tilted}
              colorTheme={colorTheme}
            />
          ))}
          <styled.BottomTabContainer>
            <styled.Heading>
              <Heading levels={[500, 400]}>{intl.formatMessage({ id: 'upNext' })}</Heading>
            </styled.Heading>
            <TimelineTab
              heading={nextItem.heading}
              description={nextItem.description}
              image={nextItem.image}
              active={false}
              click={handleNext}
            />
          </styled.BottomTabContainer>
        </div>
      </styled.Wrapper>
    </Container>
  );
}

export default Timeline;
